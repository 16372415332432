<template>
  <div class="login">
    <h1>Admin Login</h1>
    <br />
    <div v-if="isReady">
      <div class="error" v-if="errorMessage">{{errorMessage}}</div>
      <button @click="logOut_Click" v-if="this.$store.getters.isLoggedIn">Log out</button>
      <button @click="logIn_Click" v-else>Log in with Google</button>
    </div>
  </div>
</template>

<script>
import adminsService from '../services/admins.js';

export default {
  name: 'login',
  data () {
    return {
      isReady: false,
      errorMessage: null
    }
  },
  methods: {
    logIn_Click: async function() {
      this.errorMessage = null;
      this.isReady = false;

      const googleUser = await this.$gAuth.signIn();
      if (googleUser) {
        const token = googleUser.getAuthResponse().id_token;
        this.$store.commit('setAuthenticationToken', token);
        const isLoggedIn = await this.isLoggedIn();
        if(isLoggedIn) {
          const admin = googleUser.getBasicProfile();
          this.$store.commit('setAdmin', { 
              givenName: admin.getGivenName(), 
              familyName: admin.getFamilyName(), 
              imageUrl: admin.getImageUrl(),
              email: admin.getEmail()
          });
          this.$router.push('/dashboard');
        } else {
          this.$store.commit('setAuthenticationToken', null);
        }
      }
    },
    logOut_Click: async function() {
      this.$store.commit('setAuthenticationToken', null);
      this.$store.commit('setAdmin', null);
      await this.$gAuth.signOut();
    },
    isLoggedIn: async function() {
      try {
        const isLoggedIn = await adminsService.login();
        return isLoggedIn;
      } catch (err) {
        this.errorMessage = err;
      }
      this.isReady = true;
      return false;
    }
  },
  mounted() {
    let that = this;
    console.log("Waiting for Google auth...");
    let checkGauthLoad = setInterval(function() {
      that.isReady = that.$gAuth && that.$gAuth.isInit;
      if(that.isReady) {
        console.log("Google auth ready");
        clearInterval(checkGauthLoad);
      }
    }, 1000);
  }
}
</script>