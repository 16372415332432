import api from './api';

export default {
    login: async () => {
        const response = await api.get(`/authentication/login`);
        return response.data;
    },
    register: async (emailAddress) => {
        const response = await api.post(`/admins`, emailAddress);
        return response.data;
    }
}